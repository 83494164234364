import { GrowthBook } from '@growthbook/growthbook-react';

import config from '../../config';

export const gb = new GrowthBook({
    apiHost: 'https://cdn.growthbook.io',
    clientKey: config.GROWTHBOOK_KEY,
    // Enable easier debugging during development
    enableDevMode: true,
    // Only required for A/B testing
    // Called every time a user is put into an experiment
    trackingCallback: () => {},
});
