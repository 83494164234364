import { Routes, Route, Navigate } from 'react-router-dom';
import React from 'react';

// router
import { PUBLIC } from 'router/routes';

const Router = () => {
    return (
        <Routes>
            {Object.values(PUBLIC).map(({ path, component: Component }: any) => (
                <Route key={path} path={path} element={<Component />} />
            ))}
            <Route path="*" element={<Navigate to={PUBLIC.AL_GRAPH.path} />} />
        </Routes>
    );
};

export default Router;
