import { Main } from 'wikr-core-analytics';

import config from 'config';

export const initAmazonAnalytic = () => {
    Main.initSystems({
        activeSystems: [
            {
                name: 'amazon',
                id: config.AMAZON_ID,
                config: {
                    releaseDate: config.RELEASE_DATE,
                    streamName: config.AWS_DELIVERY_STREAM_NAME,
                    envMode: config.ENV,
                },
            },
        ],
    });
};

export const initAnalytics = () => {
    Main.initSystems({
        activeSystems: [
            {
                name: 'pixel',
                id: config.PIXEL_ID,
            },
        ],
    });
};
