import { Main } from 'wikr-core-analytics';

import config from 'config';

// constants
import { CUSTOM_ATTRIBUTION_LINK_CPP } from 'constants/links';

// helpers
import { getCookie, getUrlParams } from 'helpers/helpers';

export const useAttributionAnalytic = (customLink?: boolean, growthbook_test_value?: string) => {
    const urlParams = JSON.stringify(getUrlParams());

    Main.customData('attribution__screen__load', { screen_id: growthbook_test_value });

    const handleClick = () => {
        Main.customData('ViewContent', { screen_id: growthbook_test_value });
        Main.customData('install_click_attribution', { screen_id: growthbook_test_value });

        const baseUrl = customLink ? CUSTOM_ATTRIBUTION_LINK_CPP : config.ATTRIBUTION_BASE_URL;

        const urlParamsNormalized = JSON?.parse(urlParams);
        const pid = urlParamsNormalized?.utm_source || '';
        const c = urlParamsNormalized?.utm_campaign || '';
        const af_ad_id = urlParamsNormalized?.ad_id || '';
        const af_ad = urlParamsNormalized?.ad_id || '';
        const fbp = getCookie('_fbp') ? getCookie('_fbp') : '';

        const url = new URL(baseUrl);

        url.searchParams.set('pid', pid);
        url.searchParams.set('c', c);
        url.searchParams.set('af_ad_id', af_ad_id);
        url.searchParams.set('af_ad', af_ad);
        if (growthbook_test_value) {
            url.searchParams.set('al_test', growthbook_test_value);
        }
        if (fbp) {
            url.searchParams.set('fbp', fbp);
        }

        Main.customData('install_redirect_attribution', { screen_id: growthbook_test_value });

        setTimeout(() => {
            document.location = url.href;
        }, 1600);
    };

    return { handleClick };
};
