import { Button, Text } from 'wikr-core-components';
import { Trans } from 'react-i18next';
import React from 'react';

import Logo from 'pages/AlSecure/img/logo.svg';

// styles
import styles from './ExaminexContainer.module.css';

// components

import { IAiSecureContainerProps } from 'types/pages/commonTypes';

// subComponents

function ExaminexContainer({ toNextPage }: IAiSecureContainerProps) {
    return (
        //  <Box paddingX={16} paddingTop={24} paddingBottom={24} backgroundColor="surface-main">
        <div>
            <Logo className={styles.logo} />
            <div className={styles.examinexContainer} onClick={toNextPage}>
                <h1 className={styles.titleText}>
                    <Trans
                        i18nKey="examinex.title"
                        components={{
                            1: <span className={styles.examinexTitleSpan1} />,
                            2: <span className={styles.examinexTitleSpan2} />,
                        }}
                    />
                </h1>
                {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                <video
                    src="https://videos.ctfassets.net/y0gz53fkm5u0/7dzUnWwOXTUqPTgbkrboAN/182495e2dc4a2b06bfab63398a87932f/Attribution_landing_graph__mobile__x3size.mp4"
                    className={styles.responsiveVideo}
                    muted={true}
                    autoPlay={true}
                    playsInline={true}
                />
                <Text className={styles.captionText}>
                    <Trans i18nKey="examinex.caption" />
                </Text>
                <Button withPulsating className={styles.actionButton}>
                    <Text type="medium-button">
                        <Trans i18nKey="examinex.button" />
                    </Text>
                </Button>
            </div>
        </div>

        //  </Box>
    );
}

export default ExaminexContainer;
