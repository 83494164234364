import { Text, Image, Button } from 'wikr-core-components';
import { Trans } from 'react-i18next';
import React, { useState, useEffect } from 'react';

// images
import Logo from 'pages/AlSecure/img/logo.svg';
import main_image_small from 'pages/AlSecure/img/image_small.webp';
import main_image_big from 'pages/AlSecure/img/image_big.webp';

// styles
import styles from './AlSecureContainer.module.css';

// components

import Stars from '../../assets/icons/fullStars.svg';

import { IAiSecureContainerProps } from 'types/pages/commonTypes';

// subComponents

function AlSecureContainer({ toNextPage }: IAiSecureContainerProps) {
    // const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [imageSrc, setImageSrc] = useState(window.innerWidth > 420 ? main_image_big : main_image_small);

    useEffect(() => {
        const handleResize = () => {
            // setWindowWidth(window.innerWidth);
            setImageSrc(window.innerWidth > 420 ? main_image_big : main_image_small);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        //  <Box paddingX={16} paddingTop={24} paddingBottom={24} backgroundColor="surface-main">
        <div>
            <Logo className={styles.logo} />
            <div className={styles.centeredDiv} onClick={toNextPage}>
                <h1 className={styles.titleText}>
                    <Trans i18nKey="alSecure.title" components={{ 1: <span className={styles.secureTitleSpan1} /> }} />
                </h1>
                <Image alt="main image" src={imageSrc} className={styles.responsiveImage} />
                <div className={styles.starsRating}>
                    <Stars className={styles.stars} />
                    <Text className={styles.ratingText}>
                        <Trans i18nKey="alSecure.ratingInfo" />
                    </Text>
                </div>
                <Button withPulsating className={styles.actionButton}>
                    <Text type="medium-button">
                        <Trans i18nKey="alSecure.button" />
                    </Text>
                </Button>
            </div>
        </div>

        //  </Box>
    );
}

export default AlSecureContainer;
