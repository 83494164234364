import Examinex from 'pages/Examinex';
import AlSecure from 'pages/AlSecure';
import AlGraph from 'pages/AlGraph';

export const PUBLIC = {
    EXAMINEX: {
        path: '/examinex',
        component: Examinex,
    },
    AL_SECURE: {
        path: '/al-secure',
        component: AlSecure,
    },
    AL_GRAPH: {
        path: '/al-graph',
        component: AlGraph,
    },
};

export default {
    PUBLIC,
};
