import { useEffect, useState } from 'react';

import { initAmazonAnalytic, initAnalytics } from 'services/Analytics';

import { v4 as uuidv4 } from 'uuid';
// import { gb } from './growthbook';

function useAppInit() {
    const [initialized, setInitialized] = useState(false);
    let uuid = localStorage.getItem('uuid');

    if (!uuid) {
        uuid = uuidv4();
        localStorage.setItem('uuid', uuidv4());
    }

    useEffect(() => {
        initAmazonAnalytic();
        initAnalytics(); // TODO remove this after ONETrust is implemented
        // gb.setAttributes({
        //     deviceId: uuid,
        // });
        //
        // const fetchData = async () => {
        //     await gb.loadFeatures({ autoRefresh: true });
        //     setInitialized(true);
        // };
        //
        // fetchData();
        setInitialized(true);
    }, []);

    return initialized;
}

export default useAppInit;
