import { useLocation } from 'react-router-dom';
import React from 'react';

// hooks
import { useAttributionAnalytic } from 'hooks';

// containers

// helpers
import { updateGlobalLoaderVisibility } from 'helpers/helpers';
import sendAnalyticsWithEventLabel from 'helpers/analytic/sendAnalyticsWithEventLabel';

import AlSecureContainer from 'containers/AlSecureContainer';

function AlSecure() {
    const location = useLocation();
    const { handleClick } = useAttributionAnalytic();

    const toNextPage = () => {
        updateGlobalLoaderVisibility(true);
        sendAnalyticsWithEventLabel({
            url: location.pathname,
            eventValue: 'get_the_app',
        });

        handleClick();
    };

    return <AlSecureContainer toNextPage={toNextPage} />;
}

export default AlSecure;
