import { Theme } from 'wikr-core-components';
import { useState } from 'react';

import { DEFAULT_FAVICON_URL } from 'constants/links';

type ThemeConfigScheme = {
    inlineStyle: object;
    themeStyle: object;
    faviconUrl: string;
    logoUrl: string;
};

const useThemeConfiguration = (themeConfig: Record<string, ThemeConfigScheme>): Theme => {
    const [theme, setTheme] = useState<Theme>();

    if (!theme) {
        const hostName = window.location.host;

        const { themeStyle, faviconUrl } = themeConfig?.[hostName] || themeConfig['default'];

        const faviconElement = (<HTMLLinkElement>document.getElementById('favicon')) as HTMLLinkElement;

        faviconElement.href = faviconUrl || DEFAULT_FAVICON_URL;

        setTheme(themeStyle as Theme);
    }

    return theme ?? (themeConfig['default'].themeStyle as Theme);
};

export default useThemeConfiguration;
