import { useLocation } from 'react-router-dom';
import React from 'react';
// hooks

import { useAttributionAnalytic } from 'hooks';

// containers

// helpers
import { updateGlobalLoaderVisibility } from 'helpers/helpers';
import sendAnalyticsWithEventLabel from '../../helpers/analytic/sendAnalyticsWithEventLabel';

import ExaminexContainer from '../../containers/ExaminexContainer';

function AlGraph() {
    const location = useLocation();
    const { handleClick } = useAttributionAnalytic(false);
    const toNextPage = () => {
        updateGlobalLoaderVisibility(true);
        sendAnalyticsWithEventLabel({
            url: location.pathname,
            eventValue: 'get_the_app',
        });

        handleClick();
    };

    return <ExaminexContainer toNextPage={toNextPage} />;
}

export default AlGraph;
