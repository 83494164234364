import { useLocation } from 'react-router-dom';
import React from 'react';
// hooks
import { useFeatureValue } from '@growthbook/growthbook-react';

import { useAttributionAnalytic } from 'hooks';

// containers

// helpers
import { updateGlobalLoaderVisibility } from 'helpers/helpers';
import sendAnalyticsWithEventLabel from '../../helpers/analytic/sendAnalyticsWithEventLabel';

import ExaminexContainer from '../../containers/ExaminexContainer';
import AlSecureContainer from '../../containers/AlSecureContainer';

function Examinex() {
    const location = useLocation();
    const alTest = useFeatureValue('1_al_graph', 'null');
    const { handleClick } = useAttributionAnalytic(false, alTest);
    const toNextPage = () => {
        updateGlobalLoaderVisibility(true);
        sendAnalyticsWithEventLabel({
            url: location.pathname,
            eventValue: 'get_the_app',
        });

        handleClick();
    };

    if (alTest === 'al_graph') {
        return <ExaminexContainer toNextPage={toNextPage} />;
    } else {
        return <AlSecureContainer toNextPage={toNextPage} />;
    }
}

export default Examinex;
