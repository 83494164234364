import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import React from 'react';

// components
import App from './App';

// styles
import './styles.css';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const domNode = document.getElementById('app')!;
const root = createRoot(domNode);

root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>
);
