import { ThemeProvider } from 'wikr-core-components';
import React from 'react';
import { GrowthBookProvider } from '@growthbook/growthbook-react';
// services
import useI18next from './services/i18n';

// router
import useThemeConfiguration from './hooks/useThemeConfiguration/useThemeConfiguration';
import useAppInit from './hooks/useAppInit/useAppInit';
import { gb } from './hooks/useAppInit/growthbook';

import { updateGlobalLoaderVisibility } from './helpers/helpers';

import themeConfig from './themeConfig.json';
import Router from './router';

function App() {
    const initialized = useAppInit();
    const { isTranslationsLoaded } = useI18next();

    updateGlobalLoaderVisibility(!isTranslationsLoaded);

    const theme = useThemeConfiguration(themeConfig);

    if (!initialized) {
        return null;
    }

    // Add OneTrustProvider here
    return (
        <GrowthBookProvider growthbook={gb}>
            <ThemeProvider theme={theme}>{isTranslationsLoaded ? <Router /> : null}</ThemeProvider>
        </GrowthBookProvider>
    );
}

export default App;
